import React from "react";
import logo from "../images/logo.svg";
import { NavBar } from "./nav";

export const Header = () => {
  return (
    <div>
      <a href="https://lemieuxskincare.com/">
        <div className="mx-auto py-5 w-40">
          <img alt="Le Mieux" src={logo}></img>
        </div>
      </a>
      <NavBar />
    </div>
  );
};
