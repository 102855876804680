import React from "react";
import { Header } from "../../components/header";
import { StaticImage } from "gatsby-plugin-image";
import Video from "./compressed.mp4";
import "./styles.scss";

const O2Page = () => {
  return (
    <div className="h-screen overflow-scroll">
      <Header />
      <div className="items-center content-center relative mx-auto">
        <div className="hero-copy w-full lg:w-3/4 xl:w-1/2 h-full flex flex-col items-center justify-end lg:justify-center lg:p-10 text-center absolute z-10">
          <h2 className="font-serif uppercase text-black lg:text-white tracking-wider">
            revolutionary radiance
          </h2>
          <p className="mt-3 lg:mt-7 text-black lg:text-white tracking-widest">
            New Ionized Oxygen Infuser instantly delivers a shot of glow-giving
            oxygen to stimulate vital cell energy and supercharge your skin.
          </p>
          <a
            className="mt-3 lg:mt-8 text-xl uppercase block border-black lg:border-white border-2 text-black lg:text-white"
            href="https://lemieuxskincare.com/products/the-glow-team"
          >
            Shop Now
          </a>
        </div>
        <StaticImage
          src="./hero.jpg"
          alt="hero"
          placeholder="blurred"
          layout="fullWidth"
          className="hidden lg:block"
        />
        <StaticImage
          src="./hero_tablet.jpg"
          alt="hero"
          placeholder="blurred"
          layout="fullWidth"
          className="hidden md:block lg:hidden"
        />
        <StaticImage
          src="./hero_mobile.jpg"
          alt="hero"
          placeholder="blurred"
          layout="fullWidth"
          className="block md:hidden"
        />
      </div>

      {/*
                <div className="container mx-auto p-5 lg:p-20">
                  <h2 className="text-center font-light text-lg uppercase">
                    Featured In
                  </h2>
                  <div className="py-5 flex flex-row lg:justify-between justify-center flex-wrap">
                    <div className="w-1/3 lg:w-1/6 flex justify-center mb-5 lg:mb-0">
                      <img src="/logo.svg" className="block w-2/3 lg:w-full"></img>
                    </div>
                    <div className="w-1/3 lg:w-1/6 flex justify-center mb-5 lg:mb-0">
                      <img src="/logo.svg" className="block w-2/3 lg:w-full"></img>
                    </div>
                    <div className="w-1/3 lg:w-1/6 flex justify-center mb-5 lg:mb-0">
                      <img src="/logo.svg" className="block w-2/3 lg:w-full"></img>
                    </div>
                    <div className="w-1/3 lg:w-1/6 flex justify-center">
                      <img src="/logo.svg" className="block w-2/3 lg:w-full"></img>
                    </div>
                    <div className="w-1/3 lg:w-1/6 flex justify-center">
                      <img src="/logo.svg" className="block w-2/3 lg:w-full"></img>
                    </div>
                  </div>
                </div> */}

      <div className="bg-gray-100 section-block my-10 pt-5">
        <h2 className="font-serif yell">
          Discover Instant Volume and Glass-Skin Glow
        </h2>
        <div className="mt-5 container mx-auto">
          <video
            controls={false}
            className="w-full object-cover"
            autoPlay
            loop={true}
          >
            <source src={Video} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="section-block mb-10 lg:mb-0">
        <h2 className="font-serif yell">
          Oxygen is everywhere. But what about your skin?
          <br />
          Science shows that your skin loses oxygen every day, causing wrinkles,
          dehydration, acne, and inflammation.
        </h2>
        <div className="side-by-side">
          <div className="asset">
            <StaticImage
              src="./pic1.jpg"
              alt="first picture"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="content px-5 lg:px-10">
            <h2 className="font-serif mb-5">GET OMG SKIN</h2>
            <p className="mb-5">
              This easy-breezy beauty tool harnesses express-action atomization
              and skin-revving molecules for firmer, brighter, and more radiant
              skin.
            </p>
            <ul className="mb-5 list-disc pl-5">
              <li className="mb-3">
                Dispenses ultra-fine particles for deeper and faster absorption
                of oxygen and skincare nutrients.
              </li>
              <li className="mb-3">Amplifies the power of your products.</li>
              <li className="mb-3">
                Gives inflamed, suffocated skin a breath of fresh air (ideal for
                maskne).
              </li>
              <li className="mb-3">
                Cooling and even application from head to toe.
              </li>
              <li>Safe for all skin types.</li>
            </ul>
            <p className="mb-14">Discover skin’s lifeline to luminosity.</p>
            <a
              className="font-semibold text-lg text-center tracking-widest bg-gray-400 w-full lg:w-1/2 py-3 text-white"
              href="https://lemieuxskincare.com/products/the-glow-team"
            >
              LEARN MORE
            </a>
          </div>
        </div>
      </div>

      <div className="section-block no-pad bg-gray-100 spillover md:mt-10">
        <div className="side-by-side">
          <div className="content text-center p-5 md:py-20 lg:py-40 order-2 md:order-1">
            <div className="w-1/2 md:w-1/3 mx-auto mb-1">
              <img alt="stars" src="/static/star-black.svg" />
            </div>
            <h2 className="mb-5 text-3xl font-serif">I love the O2 infuser</h2>
            <p className="mb-5 text-center lg:w-2/3 lg:mx-auto leading-loose">
              Because it helps me evenly distribute my serums onto my face and
              penetrate deeper into my skin. I can feel how much more hydrated
              and plump my skin has become while using the device with my
              serums. And people are commenting about my glow and how much
              better my skin looks!
            </p>
            <p className="text-center lg:w-2/3 lg:mx-auto font-bold">
              - Diana L.
            </p>
          </div>
          <div className="asset order-1 md:order-2">
            <div className="holder">
              <StaticImage
                src="./pic2.jpg"
                alt="second picture"
                placeholder="blurred"
                className="w-full block object-cover"
                layout="fullWidth"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-block no-pad bg-gray-100 spillover">
        <div className="side-by-side">
          <div className="asset">
            <div className="holder">
              <StaticImage
                src="./pic3.jpg"
                alt="third picture"
                placeholder="blurred"
                className="block object-cover"
                layout="fullWidth"
              />
            </div>
          </div>
          <div className="content text-center p-5 lg:py-16 lg:px-10">
            <h2 className="mb-5 font-serif text-left">MORE O2 FOR YOU</h2>

            <p className="text-left mb-5 leading-relaxed">
              Save up to 35% when you subscribe to OH MY GLOW ACTIVATOR and OH
              MY GLOW SERUM for $100/month. ($145 value). But that’s not all.
              You can also get 10% off Ionized Oxygen Infuser with your
              subscription.{" "}
              <span className="text-sm">* 3 months minimum subscription.</span>
            </p>

            <p className="text-left">OH MY GLOW ACTIVATOR</p>
            <ul className="text-left mb-5 list-disc pl-5 leading-relaxed">
              <li>Hyaluronic Acid Complex locks in moisture</li>
              <li>Soothes, plumps, and volumizes</li>
              <li>Real diamonds create a radiant glow</li>
            </ul>

            <p className="text-left">OH MY GLOW SERUM</p>
            <ul className="text-left mb-10 list-disc pl-5 leading-relaxed">
              <li>Skin-firming peptides and collagen boosters</li>
              <li>Vitamin B3 volumizes and illuminates</li>
              <li>Brightens and reduces redness</li>
            </ul>
            <a
              className="font-semibold text-lg text-center tracking-widest bg-gray-400 block w-full lg:w-1/2 py-3 text-white"
              href="https://lemieuxskincare.com/products/the-glow-team"
            >
              SHOP NOW
            </a>
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <StaticImage
          src="./bottom.jpg"
          alt="bottom picture"
          placeholder="blurred"
          className="block w-full"
          layout="fullWidth"
        />
      </div>

      <div className="bg-gray-100 py-20 mt-10">
        <h2 className="text-center font-bold text-4xl">GET SOME AIR</h2>
        <div className="container mx-auto mt-5 text-center lg:text-xl">
          Give suffocating skin a breath of glow-giving air and discover your
          best skin yet.{" "}
        </div>
        <a
          className="block w-11/12 md:w-1/2 lg:w-1/3 text-center font-bold tracking-widest py-2 mt-10 text-white mx-auto bg-gray-400"
          href="https://lemieuxskincare.com/products/the-glow-team"
        >
          GET STARTED
        </a>
      </div>
    </div>
  );
};

export default O2Page;
