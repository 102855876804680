import React, { useState } from "react";
import { meganav, categoryItem } from "./nav.module.css";

const navData = [
  {
    title: "By Product Type",
    contents: [
      {
        title: "Serums",
        link: "https://lemieuxskincare.com/collections/serums",
      },
      {
        title: "Cleansers",
        link: "https://lemieuxskincare.com/collections/cleansers",
      },
      {
        title: "Toners",
        link: "https://lemieuxskincare.com/collections/toners",
      },
      {
        title: "Exfoliants",
        link: "https://lemieuxskincare.com/collections/exfoliants",
      },
      {
        title: "Special Treatments",
        link: "https://lemieuxskincare.com/collections/special-treatment",
      },
      {
        title: "Masks",
        link: "https://lemieuxskincare.com/collections/masks-1",
      },
      {
        title: "Eye Care",
        link: "https://lemieuxskincare.com/collections/eye-care",
      },
      {
        title: "Moisturizers",
        link: "https://lemieuxskincare.com/collections/moisturizers",
      },
      { title: "Body", link: "https://lemieuxskincare.com/collections/body" },
      {
        title: "Beauty Tools",
        link: "https://lemieuxskincare.com/collections/beauty-tools",
      },
      { title: "Sets", link: "https://lemieuxskincare.com/collections/sets" },
      {
        title: "Travel Size",
        link: "https://lemieuxskincare.com/collections/travel",
      },
    ],
  },
  {
    title: "By Skin Type",
    contents: [
      { title: "Dry", link: "https://lemieuxskincare.com/collections/dry" },
      {
        title: "Normal",
        link: "https://lemieuxskincare.com/collections/normal",
      },
      {
        title: "Combination",
        link: "https://lemieuxskincare.com/collections/combination",
      },
      { title: "Oily", link: "https://lemieuxskincare.com/collections/oily" },
      {
        title: "Sensitive",
        link: "https://lemieuxskincare.com/collections/sensitive",
      },
    ],
  },
  {
    title: "By Skin Concern",
    contents: [
      {
        title: "Dryness & Dehydration",
        link: "https://lemieuxskincare.com/collections/dryness-dehydration-1",
      },
      {
        title: "Fine Lines & Wrinkles",
        link: "https://lemieuxskincare.com/collections/fine-lines-wrinkles-1",
      },
      {
        title: "Loss of Firmness & Elasticity",
        link:
          "https://lemieuxskincare.com/collections/loss-of-firmness-elasticity-1",
      },
      {
        title: "Dullness, Dark Spots & Uneven Tone",
        link:
          "https://lemieuxskincare.com/collections/dullness-dark-spots-uneven-tone-1",
      },
      {
        title: "Blemish-Prone",
        link: "https://lemieuxskincare.com/collections/blemish-prone",
      },
      {
        title: "Congested & Large Pores",
        link: "https://lemieuxskincare.com/collections/congested-large-pores",
      },
      {
        title: "Redness & Sensitivity",
        link: "https://lemieuxskincare.com/collections/redness-sensitivity-1",
      },
    ],
  },
];

export const NavBar = () => {
  let [megaOpen, setOpen] = useState(false);

  return (
    <div className="relative z-20">
      <nav className="flex flex-horizontal flex-wrap justify-center py-3">
        <a
          className="cursor-pointer hidden text-xl sm:block order-1"
          href="https://lemieuxskincare.com/collections/the-icons"
        >
          BESTSELLERS
        </a>
        <button
          className="md:ml-10 cursor-pointer mx-auto sm:order-2 sm:mx-0 order-last text-xl"
          onClick={() => setOpen(!megaOpen)}
          onKeyDown={() => setOpen(!megaOpen)}
        >
          <div className="flex flex-horizontal items-center">
            SHOP
            <svg
              className="ml-1 w-3 h-3"
              xmlns="http://www.w3.org/2000/svg"
              width="924"
              height="545"
              viewBox="0 0 924 545"
            >
              <path
                fill="currentColor"
                d="M0 82L82 0l381 383L844 2l80 82-461 461-80-82L0 82z"
              ></path>
            </svg>
          </div>
        </button>
        <a
          className="label md:ml-10 hidden sm:block text-xl order-3"
          href="https://lemieuxskincare.com/pages/about-le-mieux"
        >
          OUR STORY
        </a>
        <a
          className="md:ml-10 hidden sm:block text-xl order-4 label"
          href="https://www.lemieuxpro.com"
        >
          PROFESSIONALS
        </a>
      </nav>
      {megaOpen && (
        <div
          role="menu"
          tabIndex={0}
          className={meganav}
          onMouseLeave={() => {
            setOpen(false);
          }}
        >
          <div className="pb-10 pt-5 flex w-full container flex-horizontal flex-wrap">
            {navData.map((category) => (
              <div key={category.title} className={categoryItem}>
                <h2>{category.title}</h2>
                <ul>
                  {category.contents.map((item) => (
                    <li key={item.title}>
                      <a href={item.link}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
